<script lang="ts" setup>
defineOptions({
  inheritAttrs: false,
});

const [modelValue] = defineModel<boolean>({ default: false });
</script>

<template>
  <label class="label">
    <input type="checkbox" class="input" v-model="modelValue" v-bind="$attrs" hidden />
    <span class="checkbox">
      <Icon name="bi:check" class="check" />
    </span>
    <span class="text">
      <slot />
    </span>
  </label>
</template>

<style lang="scss" scoped>
@import '~/assets/styles/tw-variables.scss';
@import '~/assets/styles/tw-mixins.scss';

.label {
  gap: 1rem;
  width: 100%;
  border: none;
  display: flex;
  font-size: 1rem;
  text-align: left;
  background: none;
  align-items: center;

  &:hover {
    cursor: pointer;
  }
}

.checkbox {
  color: $white;
  width: 1.5rem;
  display: flex;
  height: 1.5rem;
  flex-shrink: 0;
  border-radius: 3px;
  align-items: center;
  justify-content: center;
  border: 1px solid $lightGray;

  .check {
    display: none;
  }
}

.text {
  width: 100%;
  display: inline-block;
  word-wrap: break-word;
}

.input:checked ~ .checkbox {
  border-color: $primary;
  background-color: $primary;

  .check {
    display: block;
  }
}

.input:disabled ~ .checkbox {
  border-color: $gray;
  background-color: $lightGray;
  cursor: not-allowed;

  .check {
    color: $gray;
  }
}
</style>
